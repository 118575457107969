<template>
  <div>
    	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
						<div class="row" style="width: 100%;">
              
							<form action="#" method="post" enctype="multipart/form-data">
								<div class="col-sm-12 customer-login">
									<h2>Product Configure</h2>
									<!-- <div class="well"> -->
										
                    <div class="form-group required" id="productname">
											<label class="control-label">Product Name</label>
											<input v-model="productname" type="text" placeholder="Enter Product Name" class="form-control" disabled/>
										</div>
                    <!-- <div class="container testimonial-group form-group" style="margin-left: 15px;">
                       <div class="row">
                      <label for="input-country" class="control-label">Category</label>
                       </div>
                      <div class="row">
                          
                        <div  class="col-xs-3">
                          <ProductCategorySelect v-bind:data="mainCategory" v-bind:level="parseInt(0)" /> 
                        </div>
                        <div  class="col-xs-3" v-for="(item ,index) in this.SeletedCategoryID" :key="index">
                           <ProductCategorySelect  v-bind:data="item" v-bind:level="parseInt(index +1)"  /> 
                        </div>                                    
                      </div>
                    </div> -->
                    

                      <div id="productbrand" class="form-group required">
                        <!-- <label class="control-label">Brand</label>
                        <b-form-input v-model="selectbrandtext" list="my-list-id"></b-form-input> -->

                        <datalist id="my-list-id">  
                          <!-- <option v-for="(item, index)  in brands" :key="index" :data-value="item._id">{{ item.brand_name }}</option>  -->
                        </datalist>
                      </div>
                      <div id="productmodel" class="form-group required" >
                        <!-- <label class="control-label">Model</label>
                        <input type="text" v-model="productmodel" placeholder="Model" class="form-control"/> -->
                      </div>
                      <div id="productshortdesc" class="form-group required" >
                        <!-- <label class="control-label">Short Description</label>
                        <textarea row = "10" v-model="shortdescription" type="text" placeholder="Short Description" class="form-control"/> -->
                      </div>
                      <!-- <div id="productshortdesc1" class="form-group required" v-if="this.elementHide === false">
                        <label class="control-label">Overview Description</label>
                        <div id="summernote">No Information.</div>
          
                      </div> -->
                      
            
                      <!-- <div id="productwarrantyperiod" class="row" v-if="this.elementHide === false">
                        <div class="col-sm-12">
                          <div class="form-group required">
                            <label for="input-country" class="control-label">Warranty Period</label>
                            <select class="form-control select-arrow" v-model="selectedwarrantyperiod" @change="haddleWrantyChange($event.target.value)">
                              <option v-for="(item, index) in warrantyperiodlist" :key="index" :value="item._id">{{item.warranty_period}}</option>                   
                            </select>
                          </div>
                        </div> 
                      </div> -->

                      <!-- <div id="warrantydescription" class="form-group " v-if="this.elementHide === false && this.selectedwarrantyperiod != '5dc403618450273df9021b6b'">
                        <label class="control-label">Warranty Description</label>
                        <div id="summernotewarranty"></div>
                      </div> -->

                      <!-- <div id="productselecteddisputeperiod" class="form-group required" v-if="this.elementHide === false">
                        <label class="control-label">Return</label>  
                        <select class="form-control select-arrow" v-model="selecteddisputeperiod">
                              <option v-for="(item, index) in disputeperiodlist" :key="index" :value="item._id">{{item.return_type}}</option>                   
                            </select>
                      </div> -->

                      <!-- <div class="form-group" v-if="this.elementHide === false">
                        <div class="row">
                          <div class="col-sm-2">
                            <label class="control-label" for="input-name">Dangerous Goods</label>
                          </div>
                          <div class="col-sm-10">
                            <div class="row">
                                <input type="checkbox" v-model="dangerous_goods" value="1" name="shipping_address">&nbsp; Battery &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="checkbox" v-model="dangerous_goods" value="2" name="shipping_address">&nbsp; Liquid &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="checkbox" v-model="dangerous_goods" value="3" name="shipping_address">&nbsp; Flammable &nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="checkbox" v-model="dangerous_goods" value="4" name="shipping_address">&nbsp; None &nbsp;&nbsp;&nbsp;&nbsp;							    		    
                            </div>
                          </div>
                        </div>
                      </div> -->
                      
                      <!-- <div id="productwitp" class="form-group" v-if="this.elementHide === false">
                        <label class="control-label">What's in the package</label>
                        <input type="text" v-model="witp" placeholder="Package Content" class="form-control"/>
                      </div> -->

                      <!-- <div id="productweight" class="row" v-if="this.elementHide === false">
                        <div class="col-sm-4">
                          <div class="form-group">
                            <label class="control-label">Package Weight - kg </label>
                            <input type="number" v-model="packageweight" placeholder="Package Weight" class="form-control"/>
                          </div>
                        </div>
                       
                      </div> -->

                      <div class="form-group" >
                        <!-- <label class="control-label">Video URL</label>
                        <input type="text" v-model="videourl" placeholder="Video URL" class="form-control"/> -->
                      </div>
                      <div id="productweight" class="row">
                        <!-- <div class="col-sm-3">
                          <div class="form-group">
                            <label class="control-label">Minimum Item</label>
                            <input type="number" v-model="min_limit" placeholder="Minimum Item" class="form-control"/>
                          </div>
                        </div> -->
                        <!-- <div class="col-sm-3">
                          <div class="form-group">
                            <label class="control-label">Maximum item</label>
                            <input type="number" v-model="max_limit" placeholder="Maximum item" class="form-control"/>
                          </div>
                        </div>
                        <div class="col-sm-6"></div>  -->
                      </div>
                    </div> 
                    <br/>

                    <!-- <div class="well" v-if="this.elementHide === false">
                      <h4>Key Information <hr></h4>  
                      <div>
                        <KeyinfoGen v-bind:keydata="this.keys" />
                      </div>
                    </div> -->
                    <br/>

                    <!-- <div class="well" style="min-height: auto;" v-if="this.elementHide === false"> -->
                      <!-- <h4>Delivery Information <hr></h4> -->

                      <!-- <div class="row">
                        <div class="col-sm-2" style="display: flex; align-items: center;">     
                          <ul class="product-options clearfix">
                            <li class="radio active">
                              <label class="radiocontainer">
                              <input type="radio" value="1"  @click="changePaymentType($event.target.value)">
                              <div class="check-background align-div " v-bind:class="classObject">
                                  <img src="@/assets/image/icon/delivery_truck.png">
                                </div>
                              </label>
                            </li>
                          </ul>
                        
                          <ul class="product-options clearfix" style="margin-left:20px;">
                            <li class="radio active">
                              <label class="radiocontainer">
                              <input type="radio" value="2" @click="changePaymentType($event.target.value)">
                              <div class="check-background align-div"
                                v-bind:class="classObject1"
                              >
                                <img src="@/assets/image/icon/store_pickup.png">
                              </div>
                              </label>
                            </li>
                          </ul>
                        </div>
                       

                      <label>Delivery Partner</label>
                      <select class="form-control select-arrow" v-model="selecteddeliverypartner" @change="haddleWrantyChange($event.target.value, 'deliveryInfo')">
                        <option v-for="(item, index) in deliverpartners" :key="index" :value="item._id">{{item.first_name + ' ' + item.last_name}}</option>                   
                      </select><br/>
                      <div class="row" v-if="!deliveryPaymentType">
                        <div class="col-sm-2">
                          <lable style="margin-right: 18px;">Handover points</lable>
                        </div>
                        <div class="col-sm-10">
                          <a data-toggle="modal" data-target="#handOverPoints" style="color: blue; font-weight: 600;">View Details</a>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-2">
                            <label style="margin-right: 18px;">Shipping Info</label>
                          </div>
                          <div class="col-sm-10">
                            <input class="ml-5" type="checkbox" v-model="is_free_shipping" value="1" name="free shipping">&nbsp; Enable Free Shipping
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <br/> -->
                    <!-- <div class="well" style="min-height: auto;" v-if="this.elementHide === false">
                      <h4>Payment Information <hr></h4>
                      <div class="form-group">
                         <div class="row">
                          <div class="col-sm-2">
                            <label style="margin-right: 18px;">Card Payment</label>
                          </div>
                          <div class="col-sm-10">
                            <input class="ml-5" type="checkbox" value="1" name="free shipping" v-model="payment_option_card" disabled>&nbsp; Supiri Pola Credit
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <!-- <br/> -->
                    <!-- <div class="well" v-if="this.elementHide === false">
                      <div v-for="(item, index) in skuMainArray" :key="index">
                        <SkuGen v-bind:itemindex="index" v-bind:keydata="skuMainArray[index]" />
                      </div>
                      <div class="row" v-if="skuMainArray[0].skuattriarray.length > 0">
                        <div class="col-sm-6 col-md-6">
                        <button type="button" @click="addSku()" :disabled="addskudisable" class="btn btn-sm merchprof-sbtn pull-left"><li class="fa fa-plus"></li> Add New</button>                     
                      </div>
                      </div>
                    </div> -->
                    <!-- <br/> -->
                    <div class="well">
                    <div class="row">
                      <div class="col-sm-6 col-md-6">
                        <h2>SKU Information</h2>
                      </div>
                    </div>
                      <div class="row" id="skutable_1">
                        <div class="col-sm-12">
                            <table id="example" v-if="skuMainArray[0].skuattriarray.length > 0" class="table table-striped table-bordered" >
                            <thead>
                              <tr>                                
                                <th scope="col">SKU</th> 
                                <th scope="col">Quantity</th>
                                <th v-for="(item, index) in this.attributes" :key="index" scope="col">{{item.attribute_name}}</th>
                                <th scope="col">Price</th>
                                <th scope="col">Special Price</th> 
                                <th scope="col">Primary</th>  
                                <!-- <th scope="col">Action</th> -->
                              </tr>
                            </thead>
                            <tbody>                              
                              <tr v-for="(column, index) in skuMainArray" :key="index">
                                <td ><input v-model="column.sku" class="form-control" type="text" value="" disabled="true"></td>  
                                <td><input v-model="column.quantity" class="form-control" type="number" value="0" :disabled="column.active_status === 2"></td>
                                <td v-for="(item, index2 ) in column.skuattriarray" :key="index2">{{ getValueSelected(item.values, item.userinput)  }}</td> 
                                <td><input v-model="column.price" class="form-control" type="number" value="0" disabled="true"></td> 
                                <td><input v-model="column.special_price" class="form-control" type="number" value="0" disabled="true"></td>                                 
                                <td class="text-center"><input v-model="sku_primaryIndex" class="" type="radio" name="skuprimary" :value="index" @change="uncheckOthers();" disabled="true"></td>
                                <!-- <td class="productconfig-action-style">
                                  <div class="col-md-6" style="padding: 0;">
                                     <button style="width:100%; margin: 2px;" type="button" @click="scrollTo(index, 'sku_');" class="btn btn-sm btn-primary" :disabled="column.active_status === 2" >Edit</button>&nbsp;&nbsp;
                                  </div>
                                  <div class="col-md-6" style="padding: 0; padding-left: 2px;" v-if="column.active_status === 1">
                                    <button style="width:100%; margin: 2px;" type="button" @click="removeSkuByIndex(index);" class="btn btn-sm btn-danger">Deactive</button>  
                                  </div>
                                  <div class="col-md-6" style="padding: 0; padding-left: 2px;" v-if="column.active_status === 2">
                                    <button style="width:100%; margin: 2px;" type="button" @click="removeSkuByIndexActive(index);" class="btn btn-sm btn btn-success">Active</button>  
                                  </div>
                                  <div class="col-md-12" style="padding: 0;">
                                    <popper trigger="click" :options="{placement: 'bottom'}">
                                      <div class="popper" style="margin: 0px; padding: 0px; width: 300px;">
                                        <span v-if="column.loading">
                                          <img src="https://media1.tenor.com/images/2df94b2cbc32b465b9ec02451f6ffcd9/tenor.gif?itemid=8696160">
                                        </span>
                                        <div v-if="!column.loading">
                                          <div style="background:#005baa;">
                                            <h4 style="padding: 0; margin: 0; line-height: 40px; color:white;">CHECK PRICE</h4>
                                          </div>
                                          <span v-if="column.noinfo != ''">{{column.noinfo}}</span>
                                          <table v-if="column.noinfo == ''" style="width: 100%; text-align:left;">
                                            <tr>
                                              <td style="padding: 10px;">Display Price</td>
                                              <td style="color:#085499; font-size: 18px; font-weight:bold;">Rs. {{column.special_price}}</td>  
                                            </tr>
                                            <tr>
                                              <td style="padding: 10px;">Delivery Deduction <br> 
                                                <small>(if free delivery enable)</small>
                                              </td>
                                              <td v-if="is_free_shipping">Rs. 0.00</td> 
                                              <td v-else>Rs. {{column.totaldelivery}}</td>   
                                            </tr>
                                            <tr>
                                              <td style="padding: 10px;">Amount to receive <br>
                                              <small>(platform fee included)</small>
                                              </td>
                                              <td>Rs. {{  (column.special_price - (column.totalprice - column.special_price)).toFixed(2) }}</td>  
                                            </tr>
                                          </table>
                                        </div>
                                      </div>
                                      <button :disabled="(column.price > 0  && column.special_price > 0) ? false : true" style="width:100%; margin: 2px;" @click="checkDeliveryPrice(column.special_price, index);" type="button" slot="reference" class="btn btn-sm btn-info">Check Price</button>
                                    </popper>
                                  </div>
                                </td> -->
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div class="row pull-right" style="padding-bottom: 10px;" v-if="this.elementHide === false">
                      <div class="col-sm-12">
                        <button type="button" @click="pushCancel()" class="btn btn-sm btn-secondary merchprof-sbtn">Cancel</button>&nbsp;&nbsp;&nbsp;      
                        <button type="button" @click="postProductData('new')" v-if="pid === '0'" :disabled="saveDisable" class="btn btn-sm merchprof-sbtn">Submit</button>            
                        <button type="button" @click="postProductData('update')" v-if="pid != '0'" :disabled="saveDisable" class="btn btn-sm merchprof-sbtn">Update</button>                           
                      </div>
                    </div>
								<!-- </div> -->
							</form>
						</div>
					</div>					
				</div>
			</div>
		</div>

    <!-- Modal -->
    <div class="modal fade" id="handOverPoints" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
        
          <div class="modal-header">
            <h3 class="modal-title" id="exampleModalLongTitle">Handover points</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" style="position: absolute; right: 17px; top: 15px;">&times;</span>
            </button>
          </div>
          
          <div class="modal-body container">
            <div v-for="(item, index) in addressArray" :key="index">
              <div class="row">
                <div class="col">
                  <label class="control-label">Point name</label>
                  <input type="text" placeholder="Point name" v-model="item.name" class="form-control"/>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label class="control-label">Address</label>
                  <input type="text" placeholder="address" v-model="item.address" class="form-control"/>
                </div>
              </div>
              <br />
            </div>
          </div>
          
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>

        </div>
      </div>
    </div> 

  </div>
</template>
<script>

import mixinCategory from "@/mixins/merchant/APICategory";
import mixinProduct from "@/mixins/merchant/APIProduct";


import SkuGen from '@/components/Merchant/Product/SkuGen'
import KeyinfoGen from '@/components/Merchant/Product/KeyinfoGen'
import ProductCategorySelect from '@/components/Merchant/Product/ProductCategorySelect'
import { functions } from 'firebase'; 

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  components: {
    'popper': Popper,
    SkuGen,
    KeyinfoGen,
    ProductCategorySelect
  },
  mixins: [mixinCategory, mixinProduct],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Dashboard",
          href: "/merchant-dashbord",
          active: false
        },
        {
          text: "Product List",
          href: "/product-store",
          active: false
        },
        {
          text: "Product Configure",
          href: "/merchant-product-configure",
          active: true
        }
      ],
      paymentTypeValue: 1,
      pickup_addresses: [],
      deliveryPaymentType: true,
      is_free_shipping: false,
      viewDis: false,
      saveDisable: false,
      addskudisable: true,
      product_id: '',
      productname: '',
      productmodel: '',
      dangerous_goods: [],
      payment_option: 1,
      payment_option_card: true,
      payment_option_cod: true,
      shortdescription: '',
      overviewDescription: '',
      selecteddisputeperiod: 'No Return',
      witp: '',
      packageweight: '',
      packagedimentions_length: '',
      packagedimentions_width: '',
      packagedimentions_height: '',
      videourl: '',
      min_limit: 0,
      max_limit: 0,
      storeListData: [],
      mainCategory: [],
      summernotewarranty: '',
      SelectedFinalCategory: '',
      SeletedCategoryID: [],
      SeletedCategoryIDStore: [],
      child: true,
      elementHide: true,
      assignedData: [],
      brands : [],
      attributes : [],
      keys: [],
      warranty_types: [],
      selectbrand: '',
      selectbrandtext: '',
      selectedwarranty: '',
      warrantyperiodlist: [],
      disputeperiodlist: [
        {
          value: "No Return",
          text: "No Return"
        },
        {
          value: "7 Days",
          text: "7 Days Return"
        },
        {
          value: "14 Days",
          text: "14 Days Return"
        }
      ],
      addressArray: [],
      selectedwarrantyperiod: '',
      selecteddeliverypartner: '',
      skuMainArray: [
        {
          skuattriarray: [],
          imgarray: []
        }
      ],
      sku_primaryIndex: 0,
      productdata : {
          "categories":[],
          "store":"5d9af3bd9e47057677ccdaa7",
          "category":"",
          "product_name":"product1",
          "brand":"5d84d37f66e5095764af10fa",
          "service":"5d9b02d6cc3a997ac1772abf",
          "model":"A5",
          "highlight":"sss",
          "description":"sss",
          "warranty_type":"5d93352d570adc5007bdb5df",
          "warranty_period":"5d9b0323cc3a997ac1772ac1",
          "dangerous_goods":[1,2],
          "warranty_policy":"policy1",
          "warranty_policy_en":"policy1",
          "english_description":"englis1",
          "english_name":"englis1",
          "english_highlight":"englis1",
          "what_is_in_box":"englis1",
          "package_weight":2.3,
          "package_dimension":"1.5,5,2",
          "video_url":"dadad",
          "keys":[],
          "simple_products":[],
          "payment_option": 1,
          "product_type": 2
        },
      brandsug: ['Small', 'Medium', 'Large', 'Extra Large'],
      deliverpartners: []
    }
  },
  watch: {
    assignedData: function(val) {
      this.updateViewByAssigned();
    },
    SelectedFinalCategory: function(val) {
      this.validateCategories();
    },
    skuMainArray: function(val) {
      this.uncheckOthers();
      this.validateImageCount();
    }
  }, 
  mounted(){
    // $(document).ready(function() {
    //   $('[data-toggle="popover"]').popover();
    //   $('#summernote').summernote({height: 200});
    //   $('#summernotewarranty').summernote({height: 200});
    // });
  },
  created(){
    this.handleBreadcrumb();
    this.getCategories();
    this.getWarrantyPeriodData();
    this.getDisputePeriodData();
    this.getProductByIdCheck();
    this.fetchDeliveryPartners();
    this.saveDisable = false;
    this.storeSelect()
  },
  computed: {
    classObject: function () {
      
      if(this.paymentTypeValue == 1){
        return 'cash-icons-active'
      }else{
        return "cash-icons-deactive"
      }
    },
    classObject1: function () {
      
      if(this.paymentTypeValue == 2){
        return 'cash-icons-active'
      }else{
        return "cash-icons-deactive"
      }
    },
    pid() {
      let url = window.location.href.split("merchant-product-view/");
      return url[1];
    },
    findSKUvalueById :function(values, selectedID){
      for (let index = 0; index < values.length; index++) {
        if(values[index]._id === selectedID){
          return values[index].value;
        } else {
          return "";
        }
      }
    }
  },
  methods: {
    storeSelect: function() {
       let mdata = JSON.parse(localStorage.merchant_data);
       this.$store.state.merchant_selectedstore = mdata.store
    },
    changePaymentType: function (val) {
      this.paymentTypeValue = val;
      if(val=== "1"){
        this.deliveryPaymentType = true;
      } else if(val==="2"){
        this.deliveryPaymentType = false;
      }
      this.fetchDeliveryPartners();
    },
    haddleCheckPaymentOption: function (index) {
      if (index === true) {
        this.payment_option = 1;
      } else if (index === false) {
        this.payment_option = 2;
      }
    },
    checkDeliveryPrice: async function(product_price, index){
      
      this.$set(this.skuMainArray[index], 'totalprice', '');
      this.$set(this.skuMainArray[index], 'totaldelivery', '');
      this.$set(this.skuMainArray[index], 'noinfo', '');
      this.$set(this.skuMainArray[index], 'loading', true);

      let data = {
        "delivery_partner": this.selecteddeliverypartner,
        "product_price": product_price,
        // "length": this.packagedimentions_length, 
        // "width": this.packagedimentions_width,
        // "height": this.packagedimentions_height
      }
      let response = await this.getDeliveryProductPrice(data);
 
      if(response.hasOwnProperty('totProdctPrice')){
        this.$set(this.skuMainArray[index], 'noinfo', '');
        this.$set(this.skuMainArray[index], 'totalprice', response.totProdctPrice);
        this.$set(this.skuMainArray[index], 'totaldelivery', response.totDeliveryPrice);
        this.$set(this.skuMainArray[index], 'loading', false);
      } else {
        this.$set(this.skuMainArray[index], 'loading', false);
        this.$set(this.skuMainArray[index], 'noinfo', 'No Information');
      }
 
    },
    fetchDeliveryPartners: async function(id) {
      this.addressArray = [];
      let quaryVal = this.deliveryPaymentType;
      let response = await this.getDeliveryPartners(quaryVal);
      if(response.length !== 0) {
        this.deliverpartners = response;
        for (let x = 0; x < response.length; x++) {
          if (id !== undefined && response[x]._id === id) {
            if (response[x].pickup_addresses.length !== 0) {
              for (let c = 0; c < response[x].pickup_addresses.length; c++) {
                let obj = {};
                obj.name = response[x].pickup_addresses[c].name;
                obj.address = response[x].pickup_addresses[c].address_line_1 + ", " + response[x].pickup_addresses[c].address_line_2 + ", " + response[x].pickup_addresses[c].city.city_name + ", " + response[x].pickup_addresses[c].region.region_name + ", " + response[x].pickup_addresses[c].province.province_name + ", " + response[x].pickup_addresses[c].country.country_name;
                this.addressArray.push(obj);
              }
            }
          }
        }
      }
    },
    haddleWrantyChange: function(val, con) {
      this.viewDis = true;
      if (con === "deliveryInfo") {
        this.fetchDeliveryPartners(val);
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getCategories: async function(){
      try {
        let status = await this.getMainCategories();
        this.mainCategory = status;
      } catch (error) {
          console.log(error);
      } 
    },
    getSubCategories: async function(id){
      try {
        let status = await this.getSubCategoryById(id);
        return status;
      } catch (error) {
          console.log(error);
      } 
    },
    getCategoryassigns: async function(id){
      try {
        let status = await this.getCategoryassignsByID(id);
        // $('#summernote').summernote({height: 150});
        // $('#summernotewarranty').summernote({height: 200});
        return status.result;
      } catch (error) {
          console.log(error);
      } 
    },
    getWarrantyPeriodData: async function(){
      try {
        let status = await this.getWarrantyPeriods(); 
        if(status.length > 0){
          this.warrantyperiodlist = status;
          this.selectedwarrantyperiod = this.warrantyperiodlist[0]._id;
        }
      } catch (error) {
          console.log(error);
      } 
    },
    getDisputePeriodData: async function(){
      try {
        let response = await this.getDisputePeriod(); 
        this.disputeperiodlist = response;
        this.selecteddisputeperiod = response[0]._id;
      } catch (error) {
          console.log(error);
      } 
    },
    updateMenuLevel: async function(level, catid, have_child) {
       
      this.child = have_child;

      this.skuMainArray = [
        {
          skuattriarray: [],
          imgarray: []
        }
      ];

      let size = parseInt(this.SeletedCategoryID.length);
      this.SeletedCategoryID.splice(level,size);
      this.SeletedCategoryIDStore.splice(level,size);

      const value = await this.getSubCategories(catid);

      this.SeletedCategoryID.splice(level, 1, value);
      this.SeletedCategoryIDStore.splice(level, 1, catid);

      this.SelectedFinalCategory = catid;
      
      if(this.child === false){
        this.assignedData = await this.getCategoryassigns(catid);
      } else {
        this.assignedData = [];
        this.SelectedFinalCategory = '';
      } 
    },
    updateViewByAssigned(){ 
      if(this.assignedData.hasOwnProperty("keys")){
         
          this.brands = this.assignedData.brands;
          if(this.brands.length > 0){
            this.selectbrand = this.brands[0]._id;
          }

          this.attributes = this.assignedData.attributes;
           //this.skuMainArray = [];
          this.skuMainArray[0].skuattriarray = JSON.parse(JSON.stringify(this.attributes)); 
          this.skuMainArray[0].price = 0;
          this.skuMainArray[0].quantity = 0;
          this.skuMainArray[0].price = 0;
          this.skuMainArray[0].special_price = 0;
          this.skuMainArray[0].is_primary = true;
          this.skuMainArray[0].sku = this.productname + ' ' + parseInt(1);
          this.skuMainArray[0].imgarray =[""]; 

          this.keys = this.assignedData.keys;

          this.warranty_types = this.assignedData.warranty_types;
          
          if(this.warranty_types.length > 0){
            this.selectedwarranty = this.warranty_types[0]._id;
          } else {
            this.selectedwarranty = '';
          }
                
      } else {
          this.brands = [];
          this.attributes = [];
          this.keys = [];
          this.warranty_types = [];

          this.selectedwarranty = '';
      }
    },
    addSku() { 
      this.skuMainArray.push({ 
        skuattriarray: JSON.parse(JSON.stringify(this.attributes)),
        price: 0,
        quantity :0,
        price: 0,
        special_price: 0,
        is_primary: false,
        sku: this.productname + ' ' + parseInt(this.skuMainArray.length + 1), 
        imgarray: [""] });
    },
    removeSkuByIndex(skuIndex) {
      if(this.skuMainArray.length > 1){
        this.skuMainArray[skuIndex].active_status = 2;
        this.sku_primaryIndex = 0;  
      }
      
      this.uncheckOthers();
    },
    removeSkuByIndexActive(skuIndex) {
      if(this.skuMainArray.length > 1){
        this.skuMainArray[skuIndex].active_status = 1;
        this.sku_primaryIndex = 0;  
      }
      
      this.uncheckOthers();
    },
    scrollTo(index, section){
      document.getElementById(section+index).scrollIntoView();
    },
    getValueSelected: function(values, selectedID) {
      for (let index = 0; index < values.length; index++) {
        if(values[index]._id === selectedID){ 
          return values[index].value ;          
        }         
      }			
    },
    uncheckOthers(){
      for (let index = 0; index < this.skuMainArray.length; index++) {
        if(index === this.sku_primaryIndex){
          this.$set(this.skuMainArray[index], 'is_primary', true); 
        } else {
          this.$set(this.skuMainArray[index], 'is_primary', false); 
        }        
      }
    },
    validateImageCount(){
      this.addskudisable = true;
      for (let index = 0; index < this.skuMainArray.length; index++) {           
        for (let indeximg = 0; indeximg < this.skuMainArray[index].imgarray.length; indeximg++) {
          if(this.skuMainArray[index].imgarray[indeximg] != ""){
            this.addskudisable = false;
          }           
        }
      }
    },
    validateWarnning(index, section){
      let self = this;
        this.$swal.fire({
                  backdrop:false,
                  position: 'center',
                  type: 'error',
                  title: 'Please Fill The Required Fields!',
                  showConfirmButton: false,
                  timer: 1500,
                  onAfterClose: () => self.scrollTo(index, section)
                }) 
    },
    postProductData: async function(mode = "new") {
      let validated = false;

      let self = this;
      var selectedBrandArray = this.brands.filter(function (el) {
        var brand_name = self.selectbrandtext.toLowerCase();
        var resbrand_name = el.brand_name.toLowerCase();
        return brand_name === resbrand_name
      });

      if(selectedBrandArray.length > 0){
        this.selectbrand = selectedBrandArray[0]._id;
      } else {
        
        if(this.selectbrandtext === "" || typeof this.selectbrandtext === "undefined"){
          this.validateWarnning('', 'productbrand');              
          return;
        }
        
        let brandObj = {
          "brand_name": this.selectbrandtext,
          "category_id": this.SelectedFinalCategory
        }

        try {
          let res = await this.postNewMerchantBrand(brandObj);
          this.selectbrand = res._id;
        } catch (error) {
          this.$swal.fire({
            position: 'center',
                    type: 'error',
                    title: error.message,
                    showConfirmButton: false,
                    timer: 1500
                  }) 
          return;
        }         
      }

      if(this.payment_option_card && this.payment_option_cod){
        this.payment_option = 1
      } else {
        this.payment_option = 2
      }
      
      if(this.selectedwarrantyperiod === '5dc403618450273df9021b6b'){
        this.productdata.product_name = this.productname;
        this.productdata.categories = this.SeletedCategoryIDStore;
        this.productdata.brand = this.selectbrand;
        this.productdata.model = this.productmodel;
        this.productdata.dangerous_goods = this.dangerous_goods;
        this.productdata.warranty_type = this.selectedwarranty;
        this.productdata.warranty_period = this.selectedwarrantyperiod;
        this.productdata.dispute_period = this.selecteddisputeperiod;
        this.productdata.what_is_in_box = this.witp;
        this.productdata.package_weight = this.packageweight;
        this.productdata.video_url = this.videourl;
        this.productdata.category = this.SelectedFinalCategory;
        this.productdata.highlight = this.shortdescription;
        this.productdata.description = this.overviewDescription;
        // this.productdata.package_dimension = this.packagedimentions_length +','+this.packagedimentions_width+','+this.packagedimentions_height;
        this.productdata.simple_products = [];
        this.productdata.keys = [];   
        this.productdata.store = this.$store.state.merchant_selectedstore;  
        this.productdata.deliver = this.selecteddeliverypartner;
        this.productdata.is_free_shipping = this.is_free_shipping;
        this.productdata.payment_option = this.payment_option;
        this.productdata.min_limit = this.min_limit;
        this.productdata.max_limit = this.max_limit;
      } else {
        this.productdata.min_limit = this.min_limit;
        this.productdata.max_limit = this.max_limit;
        this.productdata.product_name = this.productname;
        this.productdata.categories = this.SeletedCategoryIDStore;
        this.productdata.brand = this.selectbrand;
        this.productdata.model = this.productmodel;
        this.productdata.dangerous_goods = this.dangerous_goods;
        this.productdata.warranty_type = this.selectedwarranty;
        this.productdata.warranty_period = this.selectedwarrantyperiod;
        this.productdata.dispute_period = this.selecteddisputeperiod;
        this.productdata.what_is_in_box = this.witp;
        this.productdata.package_weight = this.packageweight;
        this.productdata.video_url = this.videourl;
        this.productdata.category = this.SelectedFinalCategory;
        this.productdata.highlight = this.shortdescription;
        this.productdata.description = this.overviewDescription;
        this.productdata.warranty_description = this.summernotewarranty;
        // this.productdata.package_dimension = this.packagedimentions_length +','+this.packagedimentions_width+','+this.packagedimentions_height;
        this.productdata.simple_products = [];
        this.productdata.keys = [];   
        this.productdata.store = this.$store.state.merchant_selectedstore;  
        this.productdata.deliver = this.selecteddeliverypartner;
        this.productdata.is_free_shipping = this.is_free_shipping;
        this.productdata.payment_option = this.payment_option;
      }
     
      if(mode === 'update'){
        this.productdata._id = this.product_id;
      } 

      if(this.$store.state.merchant_selectedstore === "" || this.$store.state.merchant_selectedstore === "-1" || typeof this.$store.state.merchant_selectedstore === "undefined"){
        this.$swal.fire({
                  backdrop:false,
                  position: 'center',
                  type: 'error',
                  title: 'Please Select A Store!',
                  showConfirmButton: false,
                  timer: 1000,
                  onAfterClose: () => $(window).scrollTop(0)
                })                  
        return;
      }

      
      // if(this.payment_options.length === 0){
      //   this.validateWarnning('', 'paymentoptions');              
      //   return;
      // }
      if(this.productname === "" || typeof this.productname === "undefined"){
        this.validateWarnning('', 'productname');              
        return;
      }
      if(this.selectbrandtext === "" || typeof this.selectbrandtext === "undefined"){
        this.validateWarnning('', 'productbrand');              
        return;
      }
      if(this.productmodel === "" || typeof this.productmodel === "undefined"){
        this.validateWarnning('', 'productmodel');              
        return;
      }
      // if(this.shortdescription === "" || typeof this.shortdescription === "undefined"){
      //   this.validateWarnning('', 'productshortdesc');              
      //   return;
      // }
      // if(this.selectedwarrantyperiod === "" || typeof this.selectedwarrantyperiod === "undefined"){
      //   this.validateWarnning('', 'productwarrantyperiod');              
      //   return;
      // }
      // if(this.selecteddisputeperiod === "" || typeof this.selecteddisputeperiod === "undefined"){
      //   this.validateWarnning('', 'productselecteddisputeperiod');              
      //   return;
      // }
      // if(this.witp === "" || typeof this.witp === "undefined"){
      //   this.validateWarnning('', 'productwitp');              
      //   return;
      // }
      // if(this.packageweight === "" || typeof this.packageweight === "undefined"){
      //   this.validateWarnning('', 'productweight');              
      //   return;
      // }
      // if(this.packagedimentions_length === "" || typeof this.packagedimentions_length === "undefined"){
      //   this.validateWarnning('', 'productweight');              
      //   return;
      // } 
      // if(this.packagedimentions_width === "" || typeof this.packagedimentions_width === "undefined"){
      //   this.validateWarnning('', 'productweight');              
      //   return;
      // }
      // if(this.packagedimentions_height === "" || typeof this.packagedimentions_height === "undefined"){
      //   this.validateWarnning('', 'productweight');              
      //   return;
      // }  
      
      for (let index = 0; index < this.skuMainArray.length; index++) {
        let attributes = [];
        let skuattri = this.skuMainArray[index].skuattriarray;
        for (let index = 0; index < skuattri.length; index++) {
         attributes.push({
              "attribute_id": skuattri[index]._id,
              "attribute_value_id":skuattri[index].userinput
            });         
        }

         
        if(this.skuMainArray[index].quantity === 0 || this.skuMainArray[index].quantity === "" || typeof this.skuMainArray[index].quantity === "undefined"){
          this.validateWarnning(1, 'skutable_');              
          return;
        }
        if(this.skuMainArray[index].special_price === 0 || this.skuMainArray[index].special_price === "" || typeof this.skuMainArray[index].special_price === "undefined"){
          this.validateWarnning(1, 'skutable_');              
          return;
        }

        if(this.skuMainArray[index].price === "" || typeof this.skuMainArray[index].price === "undefined"){
          this.validateWarnning(1, 'skutable_');              
          return;
        }
        let self = this;
        if(this.addskudisable === true){
          this.$swal.fire({
                    backdrop:false,
                    position: 'center',
                    type: 'error',
                    title: 'Please Add At Least One Image to SKU!',
                    showConfirmButton: false,
                    timer: 2000,
                    onAfterClose: () => self.scrollTo(0, 'sku_')
                  })                  
          return;
        }

        // REMOVE EMPTY IMAGES
        var filtered = this.skuMainArray[index].imgarray.filter(function (el) {
          return el != "";
        });

        this.skuMainArray[index].imgarray = filtered;

            
        let simple_product = {
            "is_primary": this.skuMainArray[index].is_primary,
            "sku": this.skuMainArray[index].sku,
            "quantity": this.skuMainArray[index].quantity,
            "price": this.skuMainArray[index].price,
            "special_price": this.skuMainArray[index].special_price,
            "description":"sskks",
            "free_item":"1",
            "start_promotion_date":"2018-09-09",
            "end_promotion_date":"2019-09-09",
            "attributes":attributes,
            "images": this.skuMainArray[index].imgarray,
            "active_status": this.skuMainArray[index].active_status
          }

        if(mode === 'update'){
          simple_product._id = this.skuMainArray[index]._id; 
        }
        
        this.productdata.simple_products.push(simple_product); 
      }
      // PREPAREING KEY ARRAY 
      for (let index = 0; index < this.keys.length; index++) {

        if(["radio","checkbox","dropdown"].includes(this.keys[index].key_type)){
          this.productdata.keys.push({
            "key_id": this.keys[index].key,
            "key_value_id": this.keys[index].userinput,
            "key_value_text":""
            });
        } else {
          
          if(this.keys[index].userinput === "" || typeof this.keys[index].userinput === "undefined"){
            this.validateWarnning(this.keys[index].key, 'key_');              
            return;
          }          
           
          this.productdata.keys.push({
            "key_id": this.keys[index].key,
            "key_value_id": "",
            "key_value_text": this.keys[index].userinput
            });
        }   
        
      }

      // if(this.payment_options.length === 2){
      //   this.productdata.payment_option = 1 
      // } 

      // if(this.payment_options.length === 1){ 
      //   this.productdata.payment_option = this.payment_options[0] 
      // } 

       try {
        if(mode === 'update'){
          let status = await this.putProductCreate(this.productdata);
          this.saveDisable = true;

          this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'Product Successfully Updated!',
                    showConfirmButton: false,
                    timer: 1500
                  }) 
        } else {
          let status = await this.postProductCreate(this.productdata);
          this.saveDisable = true;

          this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: 'Product Successfully Submited!',
                    showConfirmButton: false,
                    timer: 1500
                  }) 

        }
        
        this.$router.push('/product-store').catch(err => {});
        
      } catch (error) {
          console.log(error);
      }  
    },
    validateCategories() {      
      if(this.SelectedFinalCategory != ''){
        this.elementHide = false;
      } else {
        this.elementHide = true;
      }      
    },
    pushCancel() {     
      this.$router.push('/product-store')
    },
    getProductByIdCheck: async function() {

      if (this.pid !== '0') {

        try {
          let response = await this.getProductByID(this.pid);
  
          this.productname = response.product_name;
          this.product_id = response._id;
          let responseCategories = response.categories;

          for (let index = 0; index < responseCategories.length; index++) {
            let child = true;
            if(index === responseCategories.length - 1){
              child = false;
            }
            
            await this.updateMenuLevel(index, responseCategories[index], child);
            var element = document.getElementById(this.SeletedCategoryIDStore[index]);
            if (element !==null){
              element.classList.add("cactive");
            }
                         
          }

          this.selectbrand = ""

          for (let index = 0; index < this.brands.length; index++) {
            if (this.brands[index]._id === response.brand._id) {
              this.selectbrandtext = this.brands[index].brand_name;
            }
          }

          this.selectbrand = response.brand;
          this.productmodel = response.model;
          this.dangerous_goods = response.dangerous_goods;
          this.selectedwarranty = response.warranty_type;
          this.selectedwarrantyperiod = response.warranty_period;
          this.selecteddisputeperiod = response.dispute_period 
          this.witp = response.what_is_in_box;
          this.packageweight = response.package_weight;
          this.videourl = response.video_url;
          this.min_limit = response.min_limit;
          this.max_limit = response.max_limit;
          this.SelectedFinalCategory = response.category;
          this.shortdescription = response.highlight;
          this.selecteddeliverypartner = response.deliver._id != undefined ? response.deliver._id : "";
          this.deliveryPaymentType = response.deliver.pickup != undefined ? response.deliver.pickup : "";
          if(this.deliveryPaymentType === true){ 
            this.paymentTypeValue = 1;
          }else if(this.deliveryPaymentType === false){
            this.paymentTypeValue = 2;
          }
          this.fetchDeliveryPartners();
          this.fetchDeliveryPartners(this.selecteddeliverypartner, "deliveryInfo");
          this.overviewDescription = response.description;
          // $('#summernote').summernote('code', response.description);
          this.summernotewarranty = response.warranty_description;
          // $('#summernotewarranty').summernote('code', response.warranty_description);
          this.is_free_shipping = response.is_free_shipping;
          this.payment_option = response.payment_option;

          if(this.payment_option == 1){
            this.payment_option_cod = true;
            this.payment_option_card = true;
          } else {
            this.payment_option_cod = false;
            this.payment_option_card = true;
          }
          

          // if(response.payment_option){
          //   switch (response.payment_option) {
          //     case 1:
          //       this.payment_options = []
          //       this.payment_options = [2,3]
          //       break;

          //     case 2:
          //       this.payment_options = []
          //       this.payment_options = [2]
          //       break;

          //     case 3:
          //       this.payment_options = []
          //       this.payment_options = [3]
          //       break;
            
          //     default:
          //       this.payment_options = [2]
          //       break;
          //   }
          // }
          // if (response.package_dimension !== undefined) {
          //   var split_dimension = response.package_dimension.split(','); 
          //   this.packagedimentions_length = split_dimension[0];
          //   this.packagedimentions_width = split_dimension[1];
          //   this.packagedimentions_height = split_dimension[2];
          // }
 
          for (let indexkey = 0; indexkey < this.keys.length; indexkey++) {
     
            for (let indexreskey = 0; indexreskey < response.keys.length; indexreskey++) {
                 
             if(this.keys[indexkey].key === response.keys[indexreskey].key_id){    
               
                if(response.keys[indexreskey].key_value_id){ 
                  
                  this.$set(this.keys[indexkey], 'userinput', response.keys[indexreskey].key_value_id);
                  this.$set(this.keys[indexkey], 'edituserinput', response.keys[indexreskey].key_value_id);
                } else { 
                  this.$set(this.keys[indexkey], 'userinput', response.keys[indexreskey].key_value_text); 
                }
              }                 
            }      

          }
 
          let skuBaseObject = (JSON.stringify(this.skuMainArray[0]));

          this.skuMainArray = [];

          for (let indexsmp = 0; indexsmp < response.simple_products.length; indexsmp++) {
            
             this.skuMainArray.push(JSON.parse(skuBaseObject));
            
             this.$set(this.skuMainArray[indexsmp], 'is_primary', response.simple_products[indexsmp].is_primary); 
             this.$set(this.skuMainArray[indexsmp], 'price', response.simple_products[indexsmp].price); 
             this.$set(this.skuMainArray[indexsmp], 'special_price', response.simple_products[indexsmp].special_price); 
             this.$set(this.skuMainArray[indexsmp], 'quantity', response.simple_products[indexsmp].quantity); 
             this.$set(this.skuMainArray[indexsmp], 'sku', response.simple_products[indexsmp].sku); 
             this.$set(this.skuMainArray[indexsmp], '_id', response.simple_products[indexsmp]._id);
             this.$set(this.skuMainArray[indexsmp], 'active_status', response.simple_products[indexsmp].active_status);
              

             for (let indexresattri = 0; indexresattri < response.simple_products[indexsmp].attributes.length; indexresattri++) {
                
               this.$set(this.skuMainArray[indexsmp].skuattriarray[indexresattri], 'userinput', response.simple_products[indexsmp].attributes[indexresattri].attribute_value_id);
               this.$set(this.skuMainArray[indexsmp].skuattriarray[indexresattri], 'edituserinput', response.simple_products[indexsmp].attributes[indexresattri].attribute_value_id);
               
             }
             
             this.skuMainArray[indexsmp].imgarray = [];

             for (let indexresimgs = 0; indexresimgs < response.simple_products[indexsmp].images.length; indexresimgs++) {
                this.skuMainArray[indexsmp].imgarray.push(response.simple_products[indexsmp].images[indexresimgs].image);
             }

          }          
          
        } catch (error) {
          console.log(error); 
        }
      }
    },
  }
}
</script>
<style>

.select-arrow {
  -webkit-appearance: menulist;
    box-sizing: border-box;
    align-items: center;
    white-space: pre;
    -webkit-rtl-ordering: logical;
    color:#555;
    background-color: white;
    cursor: default;
    border-width: 1px;
    border-style: solid;
    border-image: initial;
}

.productconfig-action-style {
  width: 180px;
} 

/* The heart of the matter */
.testimonial-group > .row {
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .col-xs-3 {
  display: inline-block;
      margin: 0px;
    padding: 0px;
  float: none;
}
.cash-icons-active{
  color: white!important;
  background-color: #d5d5d5;
}
.cash-icons-deactive{
  color: black!important;
  background-color: white;
}
.icons-active{
  color: white!important;
}
.icons-deactive{
  color: black!important;
}
.cactive{
  background-color: #d7d7d7;
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) { overflow-y: visible !important; }

/* Decorations */
.radiocontainer input {
position: absolute;
opacity: 0;
cursor: pointer;
}

.checkmark {
position: relative;
top: 0;
left: 0;
/* height: 25px; */
/* width: 25px; */
padding: 8px 14px;
border-radius: 5%;
margin-right:10px;
}

.checkmark:after {
content: "";
position: absolute;
display: none;
}

.radiocontainer input:checked ~ .checkmark:after {
display: block;
}

.radiocontainer .checkmark:after {
top: 10px;
left: 8px;
/* width: 20px;
height: 20px; */
border-radius: 5%;
background:transparent;
}

/* ****************************** */

.check-background {
position:relative;
border-radius: 5%;
border:1px solid #428bff;
/* background-color: white; */
color: black!important;
/* padding: 8px 10px;
margin-right:10px; */
}

.check-background:after {
content: "";
position: absolute;
display: none;
}
.align-div{
    width: 70px;
    height: 70px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
}
.radiocontainer input:checked ~ .check-background:after {
display: block;
}

.radiocontainer .check-background:after {
top: 0px;
left: 0px;
/* width: 100%;
height: 100%; */
border-radius: 5%;
/* background-color: rgb(0, 91, 170); color: rgb(255, 255, 255); */
color: white !important;
border:1px solid #666666;
}
.merchprof-sbtn{
  background: #ffd338;
  color: #000000;
  border: none;
}
.merchprof-sbtn:hover{
  color: #ffffff;
  background: #ffd338;
}
</style>